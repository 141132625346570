import { useEffect } from 'react';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';
import SingleText from '../UI/SingleText';
import { globals } from '../../data/global-vars';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import { Box, Fab, Zoom, useMediaQuery, useScrollTrigger } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import { useCallback } from 'react';

//import classes from './privacyPolicy.module.css';

function PrivacyPolicy() {
  const params = useParams();
  const brand = params.brand;
  const brandHouse = useBrandFind(brand, 'house');
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);
  const isMobile = useMediaQuery('(max-width:900px)');
  // trigger for scroll up button
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // change auto uncle button if scroll up button appears
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--au-bottom-offset',
      24 + (trigger && 50) + 'px'
    );
    // console.log('trigger');
  }, [trigger, isMobile]);

  return (
    <>
      <div
        className="container"
        style={{
          textAlign: 'left',
          color: 'var(--text-color)',
          fontWeight: 200,
          overflow: 'hidden',
        }}
      >
        <PageHeading title={globals.PAGES.DATENSCHUTZ.name}>
          {globals.PAGES.DATENSCHUTZ.name}
        </PageHeading>
        <SingleText id={brandHouse.DATENSCHUTZERKLAERUNG_ID} field="text" />
      </div>
      <Zoom in={trigger}>
        <Box
          role="presentation"
          sx={{
            position: 'fixed',
            bottom: 32,
            right: 32,
            zIndex: 1,
          }}
        >
          <Fab
            onClick={scrollToTop}
            color="primary"
            size="small"
            aria-label="scroll back to top"
            sx={{ borderRadius: '8px' }}
          >
            <KeyboardArrowUp />
          </Fab>
        </Box>
      </Zoom>
    </>
  );
}

export default PrivacyPolicy;
