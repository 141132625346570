import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, Link as ReactLink, useHistory } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';
import {
  AddNewCarModel,
  EditNewCarModel,
  SortNewCarModels,
} from '../Cars/NewCar';
import classes from './Neuwagen.module.css';
import SuggestedCars from '../Cars/SuggestedCars';
import PageHeading from '../UI/PageHeading';
import StaffWidgets from '../About/StaffWidgets';
import Animation from '../UI/Animation';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import SwipeableViews from 'react-swipeable-views';
import {
  experimentalStyled as styled,
  Tab,
  Skeleton,
  Box,
  Fab,
  useScrollTrigger,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// IMPORT FONT AWESOME ICONS
import {
  faCircleInfo,
  faPaintBrush,
  faComments,
  faCar,
  faCircleMinus,
  faList,
  faPlusCircle,
  faPenSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// IMPORT DATA
import { globals } from '../../data/global-vars';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';
import useFetchContent from '../../hooks/useFetchContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import { StyledTabs, TabPanel } from '../UI/TabController';
import Neuwagenbestand from './Neuwagenbestand';
import { KeyboardArrowUp } from '@mui/icons-material';
import CompareContext from '../../store/compare-context';
import { useCallback } from 'react';

const Link = styled(ReactLink)`
  color: inherit;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-size: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Neuwagen() {
  const theme = useTheme();
  const params = useParams();
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { isLoading, error, getNewCars } = useFetchContent();
  const { error: deleteError, deleteNewCar } = useDeleteContent();

  const [cars, setCars] = useState([]);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const compareCtx = useContext(CompareContext);

  // style
  const brand = params.brand;
  const brandObject = useBrandFind(brand);

  const [tabPanel, setTabPanel] = useState(
    localStorage.getItem('detailId') !== null ? 1 : 0
  );
  const tabKey = params[0].split('/');
  useEffect(() => {
    if (tabKey[0] === 'bestand' || localStorage.getItem('detailId') !== null)
      setTabPanel(1);
    else setTabPanel(0);
  }, [tabPanel, tabKey]);

  useEffect(() => {
    getNewCars(brandObject.make, (d) => {
      if (!error) {
        setCars(d);
        if (!modelsLoaded) setModelsLoaded(true);
      }
    });
  }, [brand]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabPanel]);

  function showModelModal(car) {
    // const lines = [
    //   { icon: faTag, text: 'SUV' },
    //   { icon: faGear, text: 'Automatik / Schaltung' },
    //   { icon: faGauge, text: 'ab 100 PS' },
    //   { icon: faDoorClosed, text: '5-türig' },
    // ];

    const body = (
      <Animation dir="left">
        <Grid container columnSpacing={1} sx={{ marginBottom: '20px' }}>
          <Grid container xs={12}>
            {/* LEFT PANEL */}
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={
                  car.image && 'https://autohaus-seyfarth.de' + car.image.src
                }
                className={classes.modalImage}
                alt={'Ansichtsfoto ' + car.makeDescription + ' ' + car.model}
                loading="lazy"
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            {/* RIGHT PANEL */}
            {/* <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: 'flex',
            }}
          > */}
            {/* QUICK INFO */}
            {/* <Grid container xs={12}>
              <Grid xs={12}>
                {car.sportsLabel && (
                  <span className={classes.sportsLabel}>
                    SEYFARTH'S SPORTWOCHEN
                  </span>
                )}
              </Grid> */}
            {/* {lines.map((line, i) => {
                return (
                  // QUICK INFO: COLUMN
                  <Grid xs={6} key={i}>
                    {/* QUICK INFO: HALF ROW 
                    <Grid container xs={12}>
                      {/* QUICK INFO ICON 
                      <Grid
                        xs={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '14px',
                          marginBottom: '14px',
                        }}
                      >
                        <FontAwesomeIcon
                          className={classes.quickInfoIcon}
                          icon={line.icon}
                        />
                      </Grid>
                      {/* QUICK INFO TEXT *
                      <Grid
                        xs={10}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          marginTop: '14px',
                          marginBottom: '14px',
                        }}
                      >
                        <span className={classes.quickInfoText}>
                          {line.text}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })} */}
            {/* QUICK INFO: FULL ROW */}
            {/* {car.motorType && (
                <Grid container xs={12} spacing={0}>
                  {/* QUICK INFO ICON 
                  <Grid
                    xs={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '14px',
                      marginBottom: '14px',
                    }}
                  >
                    <FontAwesomeIcon
                      className={classes.quickInfoIcon}
                      icon={faDroplet}
                    />
                  </Grid>
                  {/* QUICK INFO TEXT 
                  <Grid
                    xs={11}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: '14px',
                      marginBottom: '14px',
                    }}
                  >
                    <span className={classes.quickInfoText}>
                      {car.motorType.map((type, i) => {
                        if (i !== 0) return ', ' + type;
                        else return type;
                      })}
                    </span>
                  </Grid>
                </Grid>
              )} */}
            {/* <Grid
              xs={12}
              sx={{
                marginBottom: '10px',
                marginTop: 'auto',
              }}
            >
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <PriceInfo primeColor={primeColor} priceFeature="leasing" />
              </div>
            </Grid> */}
            {/* </Grid> */}
            {/* </Grid> */}
          </Grid>
          <Grid
            xs={12}
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
            }}
          >
            <Grid
              container
              xs={12}
              columnSpacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {car.infoLink && (
                <Grid xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                  <a
                    href={car.infoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button icon={faCircleInfo}>Produktinformationen</Button>
                  </a>
                </Grid>
              )}
              {car.configLink && (
                <Grid xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                  <a
                    href={car.configLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button icon={faPaintBrush}>Konfigurator</Button>
                  </a>
                </Grid>
              )}
              <Grid xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                <Button
                  icon={faCar}
                  onClick={() => {
                    history.push(
                      `/${brandObject.url}/anfrage/probefahrttermin`,
                      {
                        car: {
                          make: car.makeDescription,
                          model: car.model,
                        },
                      }
                    );
                  }}
                >
                  Probefahrt
                </Button>
              </Grid>
              <Grid xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                <Button
                  icon={faComments}
                  onClick={() => {
                    history.push(
                      `/${brandObject.url}/anfrage/beratungstermin`,
                      {
                        car: {
                          make: car.makeDescription,
                          model: car.model,
                        },
                      }
                    );
                  }}
                >
                  Beratungstermin
                </Button>
              </Grid>
            </Grid>
            <Grid xs={12} className={classes.jurInfo}>
              {car.fuelConsumption}
            </Grid>
            <Grid xs={12} className={classes.jurInfo}>
              {car.powerConsumption}
            </Grid>
          </Grid>
        </Grid>
      </Animation>
    );

    showModal(car.brand + ' ' + car.model, null, body);
  }

  function patchCars(data, action) {
    if (action === 'add') {
      if (data.brand === brandObject.make) setCars((c) => [data, ...c]);
      closeModal();
    } else if (action === 'delete') {
      setCars((items) => items.filter((item) => item._id !== data._id));
      closeModal();
    } else if (action === 'sort') {
      setCars(data);
    } else if (action === 'edit') {
      let found = false;
      setCars((arr) => {
        const updatedItems = arr.map((item) => {
          if (item._id === data._id) {
            found = true;
            return data;
          } else {
            return item;
          }
        });
        if (!found) {
          return [data, ...arr];
        }
        return updatedItems;
      });
      closeModal();
    }
  }

  // UNFINISHED
  function deleteCarModel(car) {
    showModal(
      car.model,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            deleteNewCar(car._id, (d) => {
              if (!deleteError) {
                patchCars(d, 'delete');
                setPopupMessage(
                  'Neuwagenmodelle: "' + car.model + '" enfernt.',
                  'success'
                );
              }
            });
            closeModal();
          }}
          color="red"
        >
          MODELL ENTFERNEN
        </Button>
      </div>
    );
  }

  function showAddModelHandler() {
    showModal(
      'Modell hinzufügen',
      null,
      <AddNewCarModel patchCars={patchCars} />
    );
  }
  function showManageModelHandler() {
    showModal(
      'Modelle sortieren',
      null,
      <SortNewCarModels items={cars} patchCars={patchCars} />
    );
  }
  function editModelHandler(car) {
    showModal(
      'Modell bearbeiten',
      null,
      <EditNewCarModel car={car} patchCars={patchCars} />
    );
  }

  function getCorrespondingUrl(index) {
    switch (index) {
      case 0:
        return 'modelle';

      case 1:
        return 'bestand';

      default:
        return 'modelle';
    }
  }

  function handleTabChange(event, index) {
    setTabPanel(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.NEUWAGEN.url}/` + url);
  }

  function handleChangeTabIndex(index) {
    setTabPanel(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.NEUWAGEN.url}/` + url);
  }

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  // change auto uncle button if scroll up button appears
  const isMobile = useMediaQuery('(max-width:900px)');
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--au-bottom-offset',
      24 +
        (trigger && 50) +
        (compareCtx.cars.length >= 1 && !isMobile && 50) +
        'px'
    );
  }, [trigger, compareCtx.cars, isMobile]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.removeItem('detailId');
  }, []);

  // PAGE IF BRAND IS AUDI
  if (brand === globals.BRANDS.AUDI.url) {
    return (
      <div className="container">
        <PageHeading title="Neuwagen">Neuwagen</PageHeading>
        <div className={classes.audiContainer}>
          <h2>Wir sind Audi-Service-Partner</h2>
          <p>
            Als Service-Partner bieten wir keine Neuwagen an. Wir haben aber
            zahlreiche Gebrauchtwagen bei uns...
          </p>
          <Link
            to={`/${globals.BRANDS.AUDI.url}/${globals.PAGES.GEBRAUCHTWAGEN.url}/`}
            style={{ textDecoration: 'none' }}
          >
            <Button maxWidth="400px">Zu den Gebrauchtwagen</Button>
          </Link>
          <SuggestedCars />
        </div>
        <br />
        <br />
        <StaffWidgets dep="usedCarSells" />
      </div>
    );
  }
  // PAGE IF BRAND IS NOT AUDI
  else if (brand === globals.BRANDS.VW_NFZ.url) {
    return (
      <div className="container">
        <PageHeading title="Neuwagen">Neuwagen</PageHeading>
        <div className={classes.audiContainer}>
          <h2>Wir sind Service-Partner für Volkswagen Nutzfahrzeuge</h2>
          <p>
            Als Service-Partner bieten wir keine Neuwagen an. Wir haben aber
            zahlreiche Gebrauchtwagen bei uns...
          </p>
          <Link
            to={`/${globals.BRANDS.AUDI.url}/${globals.PAGES.GEBRAUCHTWAGEN.url}/`}
            style={{ textDecoration: 'none' }}
          >
            <Button maxWidth="400px">Zu den Gebrauchtwagen</Button>
          </Link>
          <SuggestedCars />
        </div>
        <br />
        <br />
        <StaffWidgets dep="usedCarSells" />
      </div>
    );
  } else
    return (
      <>
        <PageHeading title="Neuwagen">Neuwagen</PageHeading>
        <Grid xs={12} className="container">
          <Grid xs={12} className={classes.tabBar}>
            <StyledTabs
              value={tabPanel}
              indicatorColor={brandObject.primeColor}
              variant="fullWidth"
              onChange={handleTabChange}
              sx={{ marginBottom: '20px' }}
              // className={classes.tabWrapper}
            >
              <Tab
                label="Modelle entdecken"
                onClick={() => {
                  localStorage.removeItem('detailId');
                }}
              />
              <Tab label="Unser Bestand" />
            </StyledTabs>
          </Grid>
          <SwipeableViews
            axis="x"
            index={tabPanel}
            onChangeIndex={handleChangeTabIndex}
            enableMouseEvents
            style={{
              marginBottom: '40px',
              paddingLeft: 'calc(env(safe-area-inset-left))',
              paddingRight: 'calc( env(safe-area-inset-right))',
            }}
            className="noSelect"
          >
            <TabPanel value={tabPanel} index={0} dir={theme.direction}>
              <Grid xs={12} container>
                {!modelsLoaded || error || isLoading
                  ? Array.from({ length: 20 }).map((v, i) => {
                      return (
                        <Grid
                          key={i}
                          xs={6}
                          sm={4}
                          md={4}
                          className={`${classes.carModelWrapper} noSelect`}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            margin: '40px 0',
                          }}
                        >
                          <Skeleton
                            variant="rounded"
                            width="120px"
                            height="120px"
                            className={classes.prevImg}
                          />
                          <br />
                          <Skeleton
                            variant="rounded"
                            width="80px"
                            height="40px"
                          />
                        </Grid>
                      );
                    })
                  : cars.map((car, index) => {
                      return (
                        <Grid
                          key={index}
                          xs={6}
                          sm={4}
                          md={4}
                          className={classes.carModelWrapper}
                          container
                          sx={{ margin: '40px 0' }}
                        >
                          {/* <Animation dir="up" whileTap> */}
                          <div className={classes.carModel}>
                            {authCtx.isAdmin && (
                              <div className={classes.iconWrapper}>
                                <FontAwesomeIcon
                                  icon={faCircleMinus}
                                  className={classes.deleteIcon}
                                  onClick={() => deleteCarModel(car)}
                                />
                                <div className={classes.editIconCrop}>
                                  <FontAwesomeIcon
                                    icon={faPenSquare}
                                    className={classes.editIcon}
                                    onClick={() => editModelHandler(car)}
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              className={classes.imgOverlay}
                              onClick={() =>
                                showModelModal(car, brandObject.primeColor)
                              }
                            >
                              {car.image ? (
                                <img
                                  alt={car.model + ' der Firma ' + car.brand}
                                  src={`https://autohaus-seyfarth.de${car.image.src}`}
                                  className={classes.prevImg}
                                />
                              ) : (
                                <Skeleton
                                  width="100px"
                                  height="100px"
                                  sx={{ position: 'relative', top: 0 }}
                                  className={classes.prevImg}
                                />
                              )}
                              <div className={`${classes.quickInfo}`}>
                                {car.motorType &&
                                  car.motorType.map((type, i) => {
                                    if (i !== 0)
                                      return (
                                        <span key={i}>{' • ' + type}</span>
                                      );
                                    else return <span key={i}>{type}</span>;
                                  })}
                              </div>
                            </div>
                            <h2
                              style={{ textAlign: 'center', margin: '10px 0' }}
                            >
                              {car.model}
                            </h2>
                          </div>
                        </Grid>
                      );
                    })}
                {authCtx.isAdmin && (
                  <Grid xs={12} container sx={{ marginBottom: '30px' }}>
                    <Grid xs={6} className={classes.carModelManageWrapper}>
                      <div className={`${classes.carModel} noSelect`}>
                        <div onClick={showAddModelHandler}>
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className={classes.addIcon}
                          />
                        </div>
                        <h2 style={{ textAlign: 'center' }}>Hinzufügen</h2>
                      </div>
                    </Grid>
                    <Grid xs={6} className={classes.carModelManageWrapper}>
                      <div className={`${classes.carModel} noSelect`}>
                        <div onClick={showManageModelHandler}>
                          <FontAwesomeIcon
                            icon={faList}
                            className={classes.manageIcon}
                          />
                        </div>
                        <h2 style={{ textAlign: 'center' }}>Sortieren</h2>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <StaffWidgets dep="newCarSales" />
              <SuggestedCars />
            </TabPanel>
            <TabPanel
              value={tabPanel}
              index={1}
              dir={theme.direction}
              style={{ padding: '20px' }}
            >
              <Neuwagenbestand />
              <SuggestedCars />
            </TabPanel>
          </SwipeableViews>
        </Grid>
        <Zoom in={trigger}>
          <Box
            role="presentation"
            sx={{
              position: 'fixed',
              bottom: compareCtx.cars.length > 0 && !isMobile ? 84 : 32,
              right: 32,
              zIndex: 80,
            }}
          >
            <Fab
              onClick={scrollToTop}
              color="primary"
              size="small"
              aria-label="scroll back to top"
              sx={{ borderRadius: '8px' }}
            >
              <KeyboardArrowUp />
            </Fab>
          </Box>
        </Zoom>
      </>
    );
}

export default Neuwagen;
